export default {
  formFullName: 'Nombre completo',
  salesInquiries: 'Consultas de ventas',
  formEmail: 'Correo electrónico',
  formMobileNumber: 'Número de móvil',
  formAlternatePhoneNumber: 'Número de teléfono alternativo',
  formCustomerType: 'Tipo de cliente',
  formCustomerTypeUSGovernment: 'Agencia del Gobierno de EE. UU.',
  formCustomerTypeForeignGovernment: 'Agencia del Gobierno Extranjero',
  formCustomerTypePrivate: 'Sector privado/Corporativo',
  formCustomerTypeIndividual: 'Individuo',
  formCustomerTypeNGO: 'ONG',
  formCustomerTypeOther: 'Otro',
  formInquiry: 'Tu consulta',
  formInquirySUVs: 'SUVs y Sedanes',
  formInquirySWAT: 'Camiones SWAT y APC',
  formInquiryRiot: 'Control de multitudes con cañón de agua/disturbios',
  formInquiryCIT: 'Furgonetas y camiones CIT',
  formInquiryRental: 'Vehículos de alquiler',
  formInquiryParts: 'Piezas y accesorios',
  formInquiryWarranty: 'Relación de garantía',
  formInquiryDealer: 'Para ser distribuidor',
  formInquiryEmployment: 'Oportunidad de empleo',
  formInquiryOther: 'Otro',
  formContactVia: 'Prefiero ser contactado a través de',
  formContactViaMobile: 'Móvil',
  formContactViaLandline: 'Línea fija',
  formContactViaEmail: 'Correo electrónico',
  formContactViaText: 'Mensaje de texto',
  formContactViaWhatsapp: 'Whatsapp',
  formHear: '¿Cómo te enteraste de nosotros?',
  formHearInstagram: 'Instagram',
  formHearFacebook: 'Facebook',
  formHearTikTok: 'TikTok',
  formHearYouTube: 'YouTube',
  formHearSearch: 'Motor de búsqueda (Google, Bing, etc.)',
  formHearRepeat: 'Cliente recurrente',
  formHearEmail: 'Correo electrónico o boletín',
  formHearThird: 'Reseña de terceros',
  formHearReferral: 'Referencia',
  formHearOther: 'Otro',
  formCountry: 'País',
  formState: 'Estado',
  formMessage: 'Mensaje',
  formSendMessage: 'Enviar mensaje',
  formErrorsNameRequired: 'El nombre es obligatorio',
  formErrorsNameValid: 'Por favor, proporcione un nombre válido',
  formErrorsEmailRequired: 'El correo electrónico es obligatorio',
  formErrorsEmailValid: 'Dirección de correo electrónico no válida',
  formErrorsPhoneValid: 'Por favor, ingrese un número de teléfono válido',
  formErrorsMobileRequired: 'El número de móvil es obligatorio',
  formErrorsMobileValid: 'Por favor, ingrese un número de móvil válido',
  formErrorsCountryRequired: 'El país es obligatorio',
  formErrorsStateRequired: 'El estado es obligatorio',
  formSent: '¡Enviado!',
  error: 'Error',
  partsServiceWarranty: 'Piezas, Servicio y<br />Garantía',
};
